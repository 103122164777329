<template>
  <div class="row">
    <b-container fluid>
      <b-card no-body>
        <b-overlay
            :show="isLoading"
            rounded="sm"
        >
          <div class="m-2">
            <validation-observer
                ref="checkExaminationFormRef"
                #default="{invalid}"
            >
              <b-form>
                <b-row>
                  <b-col
                      cols="12"
                      md="4"
                  >
                    <b-form-group
                        label="Chương trình đào tạo/Bồi dưỡng"
                        label-for="trainingSystemId"
                    >
                      <v-select
                          v-model="filter.trainingSystemId"
                          :options="trainingSystems"
                          :reduce="option => option.value"
                          @input="onTrainingSystemsChange"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                      cols="12"
                      md="4"
                  >
                    <b-form-group
                        label="Khóa học"
                        label-for="courseId"
                    >
                      <v-select
                          v-model="filter.courseId"
                          :options="courses"
                          :reduce="option => option.value"
                          @input="onCoursesChange"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                      cols="12"
                      md="4"
                  >
                    <b-form-group
                        label="Khoa/Bộ môn"
                        label-for="departmentId"
                    >
                      <v-select
                          v-model="filter.departmentId"
                          :options="departments"
                          :reduce="option => option.value"
                          @input="onDepartmentChange"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                      cols="12"
                      md="4"
                  >
                    <b-form-group
                        label="Ngành học"
                        label-for="majorId"
                    >
                      <v-select
                          v-model="filter.majorId"
                          :options="majors"
                          :reduce="option => option.value"
                          @input="onMajorChange"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                      cols="12"
                      md="4"
                  >
                    <b-form-group
                        label="Lớp cố định"
                        label-for="classId"
                    >
                      <v-select
                          v-model="classId"
                          :options="classes"
                          :reduce="option => option.value"
                          @input="onClassChange"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group
                        label="Trạng thái"
                        label-for="status"
                    >
                      <v-select
                          id="status"
                          v-model="status"
                          :options="statusOptions"
                          :reduce="option => option.value"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group
                        label="Mã học viên"
                        label-for="code"
                    >
                      <b-form-input
                          v-model="code"
                          placeholder="Mã học viên"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                      class="text-right"
                  >
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        class="my-2"
                        title="Lấy danh sách yêu cầu phúc khảo"
                        @click="onGetList"
                    >
                      <feather-icon icon="FilterIcon" /> Danh sách
                    </b-button>
                  </b-col>
                  <b-col
                      class="text-left"
                  >
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        :disabled="invalid"
                        variant="success"
                        class="my-2"
                        title="Lưu điểm"
                        @click="onSave"
                    >
                      <feather-icon icon="CheckIcon" /> Lưu lại
                    </b-button>
                  </b-col>
                </b-row>
                <b-row class="mt-2">
                  <b-col cols="12">
                    <vue-good-table
                        :columns="columns"
                        :rows="practicalResearchStudentsCheckScore"
                        :pagination-options="paginationOptions"
                        :line-numbers="true"
                    >
                      <div
                          slot="emptystate"
                          style="text-align: center; font-weight: bold"
                      >
                        Không có bản ghi nào !
                      </div>

                      <template
                          slot="table-row"
                          slot-scope="props"
                      >
                        <template v-if="props.row.statusCheck === inProgressCheckExamination">
                          <span v-if="props.column.field === 'checkScore'">
                            <validation-provider
                                #default="{ errors }"
                                name="Điểm"
                                rules="required|between:0,10"
                            >
                              <b-form-input
                                  v-model.number="props.row.checkScore"
                                  type="number"
                                  :state="getElementState(errors)"
                                  @change="changeCell(props.row.checkScore, props.column.field, props.row.originalIndex)"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </span>
                          <span v-else-if="props.column.field === 'examinerOneId'">
                            <validation-provider
                                #default="{ errors }"
                                name="Giám khảo 1"
                                rules="required"
                            >
                              <Select2
                                  v-model="props.row.examinerOneId"
                                  :options="teacherOptions"
                                  @change="changeCell(Number(props.row.examinerOneId), props.column.field, props.row.originalIndex)"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </span>
                          <span v-else-if="props.column.field === 'examinerTwoId'">
                            <validation-provider
                                #default="{ errors }"
                                name="Giám khảo 2"
                                rules="required"
                            >
                              <Select2
                                  v-model="props.row.examinerTwoId"
                                  :options="teacherOptions"
                                  @change="changeCell(Number(props.row.examinerTwoId), props.column.field, props.row.originalIndex)"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </span>
                          <span v-else-if="props.column.field === 'noteDeny'">
                            <b-form-input
                                v-model="props.row.noteDeny"
                                @change="changeCell(props.row.noteDeny, props.column.field, props.row.originalIndex)"
                            />
                          </span>
                          <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                        </template>
                        <span v-else-if="props.column.field === 'examinerOneId'">
                          {{ getTeacherById(props.row.examinerOneId) }}
                        </span>
                        <span v-else-if="props.column.field === 'examinerTwoId'">
                          {{ getTeacherById(props.row.examinerTwoId) }}
                        </span>
                        <!-- Column: Common -->
                        <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                      </template>

                      <!-- pagination -->
                      <template
                          slot="pagination-bottom"
                          slot-scope="props"
                      >
                        <div class="d-flex justify-content-between flex-wrap">
                          <div class="d-flex align-items-center mb-0 mt-1">
                            <span class="text-nowrap"> Hiển thị 1 đến </span>
                            <b-form-select
                                v-model="filter.itemsPerPage"
                                :options="itemsPerPageOptions"
                                class="mx-1"
                                @input="(value) => props.perPageChanged({ currentPerPage: value })"
                            />
                            <span class="text-nowrap">của {{ practicalResearchStudentsCheckScore.length }} bản ghi</span>
                          </div>
                          <div>
                            <b-pagination
                                :value="1"
                                :total-rows="props.total"
                                :per-page="filter.itemsPerPage"
                                class="mt-1 mb-0"
                                @input="(value) => props.pageChanged({ currentPage: value })"
                            />
                          </div>
                        </div>
                      </template>
                    </vue-good-table>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </div>
        </b-overlay>
      </b-card>
    </b-container>
  </div>
</template>
<script>

import Ripple from 'vue-ripple-directive'
import {
  BButton,
  BCard,
  BCol,
  BContainer,
  BFormSelect,
  BOverlay,
  BPagination,
  BRow,
  BForm,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import '@core/scss/vue/libs/vue-good-table.scss'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getUser } from '@/auth/utils'
import vSelect from 'vue-select'
import { hasPermissionForResource, isSystemAdmin } from '@/utils'
import { PermissionCode, ResourceCode } from '@/const/code'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { between, required } from '@validations'
import Select2 from 'v-select2-component'
import { CHECK_EXAMINATION_PAPERS } from '@/const/status'
import { Flag } from '@/const/flag'

export default {
  name: 'CheckScorePracticalResearch',
  directives: {
    Ripple,
  },
  components: {
    BButton,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    BForm,
    BFormGroup,
    BFormInput,
    VueGoodTable,
    vSelect,
    ValidationObserver,
    ValidationProvider,
    Select2,
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      filter: {
        currentPage: 1,
        itemsPerPage: 100,
        trainingSystemId: null,
        courseId: null,
        courseSemesterId: null,
        departmentId: null,
        majorId: null,
      },
      classId: null,
      code: null,
      status: null,
      inProgressCheckExamination: Flag.IN_PROGRESS_CHECK_EXAMINATION,
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
      columns: [
        {
          label: 'Họ và tên',
          field: 'name',
          thClass: 'text-center',
        },
        {
          label: 'Mã học viên',
          field: 'code',
          thClass: 'text-center',
        },
        {
          label: 'Điểm cũ',
          field: 'score',
          width: '10%',
          thClass: 'text-center',
        },
        {
          label: 'Điểm mới',
          field: 'checkScore',
          width: '10%',
          thClass: 'text-center',
        },
        {
          label: 'Giám khảo một',
          field: 'examinerOneId',
          thClass: 'text-center',
        },
        {
          label: 'Giám khảo hai',
          field: 'examinerTwoId',
          thClass: 'text-center',
        },
        {
          label: 'Ghi chú',
          field: 'noteDeny',
          thClass: 'text-center',
        },
      ],
      required,
      between,
    }
  },
  computed: {
    ...mapGetters({
      trainingSystems: 'practicalResearchCheckScore/trainingSystems',
      courses: 'practicalResearchCheckScore/courses',
      departments: 'practicalResearchCheckScore/departments',
      majors: 'practicalResearchCheckScore/majors',
      classes: 'practicalResearchCheckScore/classes',
      teachers: 'practicalResearchCheckScore/teachers',
      practicalResearchStudentsCheckScore: 'practicalResearchCheckScore/practicalResearchStudentsCheckScore',
    }),
    paginationOptions() {
      return {
        enabled: true,
        perPage: this.filter.itemsPerPage,
      }
    },
    checkExaminationPaperCreatable() {
      return (isSystemAdmin() || hasPermissionForResource(PermissionCode.CREATE, ResourceCode.CHECK_EXAMINATION_PAPER))
          && this.filter.status === Flag.IN_PROGRESS_CHECK_EXAMINATION
    },
    teacherOptions() {
      return this.teachers.map(element => ({ id: element.id, text: element.name }))
    },
    isValidSearch() {
      return !!(this.filter.trainingSystemId
          && this.filter.courseId
          && this.filter.courseSemesterId
      )
    },
    statusOptions() {
      return CHECK_EXAMINATION_PAPERS
    },
  },
  async created() {
    this.isLoading = true
    try {
      const cUser = getUser()
      this.filter.organizationId = (cUser.orgId ? cUser.orgId : 0)
      await Promise.all([
        this.getTeachersByOrganizationId(),
        this.getTrainingSystems({ organizationId: this.filter.organizationId }),
        this.getDepartments({ organizationId: this.filter.organizationId }),
      ])
      if (this.trainingSystems.length > 0) {
        this.filter.trainingSystemId = this.trainingSystems[0].value
        await this.getCourses(this.filter)
      }
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions({
      getTrainingSystems: 'practicalResearchCheckScore/getTrainingSystems',
      getCourses: 'practicalResearchCheckScore/getCourses',
      getDepartments: 'practicalResearchCheckScore/getDepartments',
      getMajors: 'practicalResearchCheckScore/getMajors',
      getClasses: 'practicalResearchCheckScore/getClasses',
      getTeachersByOrganizationId: 'practicalResearchCheckScore/getTeachersByOrganizationId',
      readPracticalResearchStudentCheckScore: 'practicalResearchCheckScore/readPracticalResearchStudentCheckScore',
      updatePracticalResearchCheckScore: 'practicalResearchCheckScore/updatePracticalResearchCheckScore',
    }),
    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    changeCell(data, column, row) {
      this.$set(this.practicalResearchStudentsCheckScore[row], column, data)
    },
    getTeacherById(id) {
      if (!id || this.teachers.length === 0) return ''
      const found = this.teachers.find(teacher => teacher.id === id)
      return found ? found.name : ''
    },
    async onTrainingSystemsChange(event) {
      this.filter.trainingSystemId = event
      this.filter.courseId = null
      this.courseName = ''
      this.courseSemesterName = ''
      await this.getCourses(this.filter)
    },
    async onCoursesChange(event) {
      this.filter.courseId = event
      this.courseName = this.courses.find(course => course.value === event).label
      this.filter.courseSemesterId = null
      this.courseSemesterName = ''
    },
    async onDepartmentChange(event) {
      this.filter.departmentId = event
      this.filter.majorId = null
      await this.getMajors(this.filter)
    },
    async onMajorChange(event) {
      this.filter.majorId = event
      this.filter.programmeId = null
      await this.getClasses({
        courseId: this.filter.courseId,
        trainingSystemId: this.filter.trainingSystemId,
        departmentId: this.filter.departmentId,
        majorId: this.filter.majorId,
      })
    },
    async onClassChange(event) {
      this.filter.classId = event
    },
    async onGetList() {
      this.isLoading = true
      try {
        await this.readPracticalResearchStudentCheckScore({
          currentPage: this.filter.currentPage,
          itemsPerPage: this.filter.itemsPerPage,
          classId: this.classId,
          studentCode: this.code,
          status: this.status,
        })
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onSave() {
      this.isLoading = true
      try {
        const response = await this.updatePracticalResearchCheckScore(this.practicalResearchStudentsCheckScore)
        if (response) {
          const {
            isSuccessful,
            message,
          } = response
          if (isSuccessful) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            await this.onGetList()
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          }
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style scoped lang="scss">

</style>
